$c0: #232323;
$c1: #1e1e1e;
$c2: #181818;
$white: #e7e7e7;
$pink: #ffbab5;

$wrap_width: 6vw;
$nav_height: 121px;
$transition: 0.4s ease;

@import url("https://fonts.googleapis.com/css2?family=Anaheim&display=swap");

@mixin f1($fw) {
  font-family: "Anaheim", sans-serif;
  font-weight: $fw;
}

* {
  box-sizing: border-box;
}

a {
  //cursor: none;
}

//  ██████╗ ██╗   ██╗███████╗██████╗ ██╗███████╗███████╗
// ██╔═══██╗██║   ██║██╔════╝██╔══██╗██║██╔════╝██╔════╝
// ██║   ██║██║   ██║█████╗  ██████╔╝██║█████╗  ███████╗
// ██║▄▄ ██║██║   ██║██╔══╝  ██╔══██╗██║██╔══╝  ╚════██║
// ╚██████╔╝╚██████╔╝███████╗██║  ██║██║███████╗███████║
//  ╚══▀▀═╝  ╚═════╝ ╚══════╝╚═╝  ╚═╝╚═╝╚══════╝╚══════╝

$small_laptops: "screen and (max-width: 1270px)";
$tablets: "screen and (max-width: 1024px)";
$phones: "screen and (max-width: 768px)";
$mobile: "screen and (max-width: 550px)";
$small_phones: "screen and (max-width: 375px)";

.cursor {
  filter: drop-shadow(0px 0px 4px $pink);
  pointer-events: none;
  /*
    width: 30px;
    height: 30px;
    
    border: 1px solid $white;
    */
  border-radius: 50%;
  z-index: 999;
  position: absolute;
  //transform: translate(-50%, -50%);
  transition: all 50ms ease-out;
  //transition: 0.1s ease;
  mix-blend-mode: difference;
}

::selection {
  background: rgba($pink, 0.3);
  color: $white;
}
body,
html {
  scroll-behavior: smooth;
  //overflow-x:hidden;
  width: 100%;
  @media #{$phones} {
    overflow-x: hidden;
  }
}
.body_curtain {
  opacity: 0;
  visibility: hidden;
  transition: $transition;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  background-color: $c1;
  z-index: 2;
}
.menu--shown {
  .body_curtain {
    opacity: 0.75 !important;
    visibility: visible !important;
    transition: $transition;
  }
}
body {
  //cursor: none;
  background-color: $c0;
  color: white;
  @include f1(400);
  margin: 0;
  font-size: 18px;
  .page {
    background-color: $c0;
    position: relative;
    z-index: 2;
  }
  a {
    text-decoration: none;
  }
  ul {
    margin: 0;
    padding: 0;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 0;
    text-transform: uppercase;
    font-weight: 400;
    text-shadow: 1px 1px 2px pink;
    &.pink {
      color: $pink;
      text-shadow: none;
    }
  }
  h1 {
    font-size: 56px;
  }
  h2 {
    font-size: 48px;
  }
  h3 {
    font-size: 30px;
  }
  h4 {
    font-size: 1.2em;
  }

  .subtitle {
    color: $pink;
    font-size: 20px;
    display: block;
    //text-shadow: 0px 0px 2px white;
    & + .wysiwyg {
      margin-top: 20px;
    }
  }
  .bold {
    text-shadow: 1px 1px 2px pink;
  }
  .wysiwyg {
    display: block;
    &.big-text {
      font-size: 20px;
      @media #{$tablets} {
        font-size: 18px;
      }
    }
  }
  .small_text {
    font-size: 14px;
  }
  img {
    max-width: 100%;
  }
  @media #{$tablets} {
    font-size: 18px;
    h1 {
      font-size: 45px;
    }
    h2 {
      font-size: 40px;
    }
    h3 {
      font-size: 25px;
    }
    .subtitle {
      font-size: 18px;
    }
  }
}

.wrap {
  max-width: calc(1500px + (#{$wrap_width} * 2));
  margin: 0 auto;
  padding: 0 $wrap_width;
  width: 100%;
  position: relative;
}

#root {
  min-height: 100vh;
}
/*
'##::: ##::::'###::::'##::::'##:
 ###:: ##:::'## ##::: ##:::: ##:
 ####: ##::'##:. ##:: ##:::: ##:
 ## ## ##:'##:::. ##: ##:::: ##:
 ##. ####: #########:. ##:: ##::
 ##:. ###: ##.... ##::. ## ##:::
 ##::. ##: ##:::: ##:::. ###::::
..::::..::..:::::..:::::...:::::*/
.scrolldown {
  .main_menu:not(.menu--shown .main_menu) {
    transition: $transition;
    transform: translateY(-100%);
  }
}
.scrolled {
  .main_menu {
    transition: $transition;
    background-color: $c1;
  }
}
.main_menu {
  font-size: 25px;
  padding: 15px 0;
  z-index: 10;
  position: fixed;
  top: 0;
  width: 100%;
  transition: $transition;
  &__top {
    z-index: 11;
  }
  .wrap {
    display: flex;
    justify-content: space-between;
  }
  &__left {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    .logo {
      width: 89px;
      height: 89px;
      object-fit: contain;
      transition: $transition;
    }
  }
  &__right {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    position: relative;
    .switch_lang {
      position: relative;
      z-index: 2;
      cursor: pointer;
    }
  }
  .hamburger {
    display: flex;
    align-items: center;
    font-size: 40px;
    line-height: 1;
    position: sticky;
    top: 15px;
    cursor: pointer;
    .icon_opened {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .icon_closed {
      display: none;
      align-items: center;
      justify-content: center;
    }
    &::before {
      cursor: normal;
      content: "";
      position: absolute;
      z-index: -1;
      top: 50%;
      left: 50%;
      background-color: $c2;
      //border: 1px solid red;
      border-radius: 50%;
      transition: 0.8s ease;
      width: 60px;
      padding-bottom: 60px;
      transform: translate(-50%, -50%);
      transition: $transition;
    }
  }
  &__burger {
    width: 100%;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    margin-top: $nav_height;
    z-index: 11;
    opacity: 0;
    visibility: hidden;
    transition: $transition;
    .wrap {
      display: flex;
      justify-content: flex-end;
    }
    ul {
      border-radius: 25px;
      padding-right: 0;
      min-width: 300px;
      padding-top: 40px;
      //box-shadow: 2px 2px 2px $c2;
      max-width: fit-content;
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 25px;
      text-align: right;
      position: relative;
      //border: 1px solid red;
      a {
        font-size: 35px;
        color: $white;
        z-index: 6;
        overflow: hidden;
        display: block;
        span {
          opacity: 0;
          position: relative;
          transform: translateY(-100%);
          transition: $transition;
          display: block;
        }
      }
    }
  }
  @media #{$phones} {
    &__left {
      .logo {
        width: 70px;
        height: 70px;
      }
    }
    &__right {
      gap: 40px;
      .switch_lang {
        font-size: 22px;
      }
    }
    &__burger {
      ul {
        a {
          font-size: 30px;
        }
      }
    }
  }
}
.menu--shown {
  .main_menu {
    .hamburger {
      .icon_opened {
        display: none;
      }
      .icon_closed {
        display: flex;
      }
      &::before {
        width: 600px;
        padding-bottom: 600px;
        transform: translate(-52%, -27%);
        transition: 0.7s ease;
      }
    }
    .main_menu {
      &__burger {
        opacity: 1;
        visibility: visible;
        transition: $transition;
        ul {
          a {
            span {
              opacity: 1;
              transform: none;
              transition: 0.5s ease;
            }
            @for $i from 1 through 3 {
              &:nth-child(#{$i}) {
                span {
                  transition-delay: calc(#{$i} * 0.1s);
                }
              }
            }
          }
        }
      }
    }
  }
  @media #{$phones} {
    .main_menu {
      &__left {
        .logo {
          opacity: 0;
          transition: $transition;
        }
      }
    }
  }
}

/*
'##::::'##:'########:'########:::'#######::
 ##:::: ##: ##.....:: ##.... ##:'##.... ##:
 ##:::: ##: ##::::::: ##:::: ##: ##:::: ##:
 #########: ######::: ########:: ##:::: ##:
 ##.... ##: ##...:::: ##.. ##::: ##:::: ##:
 ##:::: ##: ##::::::: ##::. ##:: ##:::: ##:
 ##:::: ##: ########: ##:::. ##:. #######::
..:::::..::........::..:::::..:::.......:::*/
.hero {
  background-color: $c0;
  height: calc(100vh - 50px);
  padding-top: $nav_height;
  min-height: 700px;
  display: flex;
  align-items: center;
  padding-bottom: 50px;
  &__wrapper {
    display: flex;
    align-items: flex-end;
    max-width: 900px;
    margin: 0 auto;
  }
  &__left {
    position: relative;
    flex: 0 0 45%;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 55%;
    z-index: 1;
    img {
      max-width: unset;
      width: 110%;
      height: auto;
      bottom: 0;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &__right {
    .text {
      padding-left: 50px;
      padding-bottom: 30px;
      .wysiwyg {
        font-size: 20px;
      }
    }
  }
  ul {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  &__mobile {
    display: none;
  }
  &__item {
    padding: 0 50px;
    display: flex;
    align-items: center;
    background-color: $white;
    font-size: 24px;
    height: 74px;
    color: $c0;
    position: relative;
    width: 90%;
    &::before {
      position: absolute;
      background-color: $white;
      content: "";
      height: 100%;
      top: 0;
      left: 0;
      transform: translateX(-100%);
      //border: 1px solid red;
      width: 150px;
    }
    &::after {
      content: "";
      border-top: 37px solid transparent;
      border-bottom: 37px solid transparent;
      border-left: 37px solid $white;
      top: 0;
      left: 100%;
      position: absolute;
    }
    span {
      display: flex;
      align-items: center;
      gap: 50px;
      &::before {
        flex-shrink: 0;
        position: relative;
        content: "";
        border-radius: 50%;
        width: 10px;
        height: 10px;
        background-color: $c0;
        font-size: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &:hover {
      span {
        &::before {
          animation: move 0.6s alternate infinite linear;
          background-color: rgba(0, 0, 0, 0);
          content: url("https://api.iconify.design/ic/baseline-chevron-right.svg?color=%23232323&width=30");
        }
      }
    }
  }

  @media #{$tablets} {
    &__right {
      .text {
        .wysiwyg {
          font-size: 18px;
        }
      }
    }
  }
  @media screen and (max-width: 965px) {
    height: unset;
    &__right {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .text {
        padding: 0;
        flex: 1 1 0;
      }
      ul {
        flex: 0 0 100%;
        margin-top: -10px;
        width: 100%;
      }
    }
    &__mobile {
      display: block;
      max-width: 350px;
      order: -1;
    }
    &__left {
      display: none;
    }
    &__wrapper {
    }
  }
  @media #{$phones} {
    &__mobile {
      max-width: 300px;
    }
    &__right {
      flex-direction: column;
      .text {
        order: -2;
        text-align: center;
        max-width: 500px;
        margin-bottom: 20px;
      }
      ul {
        gap: 10px;
      }
    }
    &__item {
      font-size: 20px;
      height: 60px;
      &::after {
        border-top: 30px solid rgba(0, 0, 0, 0);
        border-bottom: 30px solid rgba(0, 0, 0, 0);
        border-left: 30px solid #e7e7e7;
      }
    }
  }
}
/*
:::'###::::'########:::'#######::'##::::'##:'########:
::'## ##::: ##.... ##:'##.... ##: ##:::: ##:... ##..::
:'##:. ##:: ##:::: ##: ##:::: ##: ##:::: ##:::: ##::::
'##:::. ##: ########:: ##:::: ##: ##:::: ##:::: ##::::
 #########: ##.... ##: ##:::: ##: ##:::: ##:::: ##::::
 ##.... ##: ##:::: ##: ##:::: ##: ##:::: ##:::: ##::::
 ##:::: ##: ########::. #######::. #######::::: ##::::
..:::::..::........::::.......::::.......::::::..:::::*/
.about {
  &__wrapper {
    position: relative;
    padding: 120px;
    background-color: $c2;
    border-radius: 30px 30px 0 0;
    display: flex;
    gap: 150px;
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100vh;
      top: 100%;
      left: 0;
      object-fit: cover;
      background-color: $c2;
    }
  }
  .header {
    text-align: center;
  }
  &__left {
    flex: 0 0 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    max-height: 100vh;
    max-width: 400px;
    position: sticky;
    top: 50px;
    height: 100%;
    .arch {
      position: relative;
      z-index: 1;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      &::before {
        border-radius: 50% 50% 0 0;
        content: "";
        background-color: $white;
        position: absolute;
        width: 90%;
        height: 90%;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        object-fit: cover;
        z-index: -1;
      }
    }
    .icon_animation {
      font-size: 70px;
      margin: 40px 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .socials {
      display: flex;
      gap: 10px;
      max-width: 100%;
      fill: $white;
      position: relative;
      color: $white;
      align-items: center;
      justify-content: center;
      a {
        top: 0;
        transition: $transition;
        display: block;
        position: relative;
        &:hover {
          top: -5px;
          transition: $transition;
        }
      }
      &::before,
      &::after {
        position: relative;
        content: "";
        height: 1px;
        background-color: $white;
        width: 40px;
      }
      &::before {
        margin-right: -10px;
      }
      &::after {
        margin-left: -10px;
      }
      path {
        stroke: $white;
        fill: $white;
      }
      svg {
        width: 30px;
      }
    }
  }
  &__right {
    flex: 1 1 0;
  }
  &__sections {
    display: flex;
    flex-direction: column;
    gap: 70px;
    h3 {
      position: relative;
      margin-bottom: 30px;
      .title {
        max-width: fit-content;
        z-index: 1;
        background-color: $c2;
        position: relative;
        padding-right: 10px;
      }
      &::after {
        content: "";
        width: calc(100% + 120px);
        height: 2px;
        background-color: $white;
        position: absolute;
        left: 0;
        top: 50%;
      }
    }
    .items {
      display: flex;
      flex-direction: column;
      gap: 40px;
      ul {
        list-style-type: none;
        columns: 2;
        break-inside: avoid-column;
        li {
          padding-left: 1em;
          position: relative;
          break-inside: avoid-column;
          margin-bottom: 10px;
          color: $pink;
          font-size: 1.2em;
          &::before {
            content: "";
            position: absolute;
            width: 4px;
            height: 4px;
            top: 9px;
            left: 0;
            background-color: $white;
            border-radius: 50%;
          }
          .years {
            display: block;
            color: $white;
          }
          .librairies {
            display: flex;
            flex-direction: column;
          }
        }
      }
      .item {
        display: flex;
        flex-direction: column;
        .title {
          color: $pink;
          text-transform: uppercase;
          font-size: 1.2em;
          margin-bottom: 10px;
          position: relative;
          svg {
            position: absolute;
            right: calc(100% + 7px);
            top: 2px;
            animation: move 0.6s alternate infinite linear;
          }
        }
        .tasks {
          margin-top: 20px;
        }
      }
    }
  }
  @media #{$small_laptops} {
    &__wrapper {
      padding: 100px 70px;
      gap: 100px;
    }
    &__sections {
      h3 {
        &::after {
          width: calc(100% + 70px);
        }
      }
    }
  }
  @media #{$tablets} {
    &__wrapper {
      flex-direction: column;
    }
    &__left {
      margin: 0 auto;
      position: relative;
      top: 0;
    }
    &__sections {
      .items {
        .item {
          .title {
            font-size: 1.1em;
          }
        }
        ul {
          li {
            font-size: 1.1em;
          }
        }
      }
    }
  }
  @media #{$phones} {
    &__sections {
      gap: 50px;
      h3 {
        &::after {
          width: calc(100% + 25px);
        }
      }
      .items {
        ul {
        }
      }
    }
    &__left {
      .icon_animation {
        font-size: 65px;
        margin: 30px 0;
      }
    }
    &__wrapper {
      padding: 70px 25px;
      gap: 50px;
    }
  }
}
/*
'########::'########:::'#######::::::::'##:'########::'######::'########::'######::
 ##.... ##: ##.... ##:'##.... ##::::::: ##: ##.....::'##... ##:... ##..::'##... ##:
 ##:::: ##: ##:::: ##: ##:::: ##::::::: ##: ##::::::: ##:::..::::: ##:::: ##:::..::
 ########:: ########:: ##:::: ##::::::: ##: ######::: ##:::::::::: ##::::. ######::
 ##.....::: ##.. ##::: ##:::: ##:'##::: ##: ##...:::: ##:::::::::: ##:::::..... ##:
 ##:::::::: ##::. ##:: ##:::: ##: ##::: ##: ##::::::: ##::: ##:::: ##::::'##::: ##:
 ##:::::::: ##:::. ##:. #######::. ######:: ########:. ######::::: ##::::. ######::
..:::::::::..:::::..:::.......::::......:::........:::......::::::..::::::......:::*/
.projects {
  margin-bottom: 100px;
  /*
    .border_radius{
        border-radius: 60px;
    }
    */
  .first_wrapper {
    padding: 0 80px;
  }
  &__wrapper {
    //background-color: $c2;
    .second_wrapper {
      background-color: $c1;
      .wrap {
        padding-top: 120px;
        padding-bottom: 120px;
      }
    }
  }
  &__top {
    margin-bottom: 80px;
    max-width: 50%;
  }
  &__list {
    display: flex;
    position: relative;
    height: 100%;
  }
  .note {
    font-size: 16px;
    max-width: 400px;
  }
  .project {
    padding: 30px 0;
    position: relative;
    display: flex;
    flex-direction: column;
    color: $white;
    .description {
      padding: 20px 0 30px;
      display: none;
    }
    .content {
      display: flex;
      flex-direction: column;
    }
    a {
      color: $white;
    }
    &.active {
      background-color: $c2;
      transition: $transition;
      &::before {
        opacity: 1;
        transition: $transition;
      }
      .arrow {
        opacity: 1;
        transition: $transition;
      }
    }
    &::before {
      content: "";
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 100%;
      background-color: $c2;
      object-fit: cover;
      transition: $transition;
    }
    .arrow {
      position: absolute;
      font-size: 50px;
      right: 70px;
      top: 50%;
      transform: translateY(-50%);
      color: $pink;
      opacity: 0;
      transition: 0.2s ease;
      animation: move_horizontal 0.6s alternate infinite linear;
    }
    .subtitle {
      font-size: 16px;
    }
    .small_text {
      margin-top: 20px;
      text-transform: uppercase;
    }
    &::after {
      content: "";
      width: 100%;
      height: 1px;
      background-color: $white;
      bottom: 0;
      left: 0;
      position: absolute;
    }
    &_img {
      height: calc(100vh - 100px);
      min-height: 500px;
      position: relative;
      /*
            position: sticky;
            top: 0;
            */
      padding: 50px;
      visibility: hidden;
      opacity: 0;
      transition: $transition;
      &.active {
        visibility: visible;
        transition: $transition;
        opacity: 1;
        z-index: 4;
      }
      .project__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: $white;
        height: 100%;
        font-size: 25px;
        /*
                &::before{
                    content: "";
                    background-color: $pink;
                    opacity: 0.5;
                    mix-blend-mode: overlay;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    object-fit: cover;
                }
                */
      }
      img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        object-position: top;
        opacity: 0.5;
        //filter: grayscale(50%);
      }
      .link {
        z-index: 4;
        width: 100%;
        height: 100%;
        position: absolute;
        object-fit: cover;
        top: 0;
        left: 0;
      }
      .categories,
      .btn,
      .description {
        position: relative;
        z-index: 3;
      }
      .description {
        font-size: 1.2rem;
        padding: 50px;
        z-index: 6;
        background-color: $c0;
      }
      .btn {
        margin: 0 auto;
        border: 1px solid $white;
        border-radius: 30px;
        padding: 12px 32px;
      }
      &:not(.default) {
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        width: 100%;
        /*
                opacity: 0;
                visibility: hidden;
                */
      }
    }
  }
  .categories {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    font-size: 18px;
    z-index: 2;
    position: relative;
    .tag {
      background-color: $white;
      padding: 5px 10px;
      border-radius: 20px;
      color: $c0;
    }
  }
  &__left {
    flex: 0 0 50%;
    .categories {
      display: none;
    }
    .img {
      display: none;
    }
  }
  &__right {
    flex: 1 1 0;
    //position: relative;
    top: 0px;
    position: sticky;
    display: flex;
    height: 100%;
  }
  @media #{$tablets} {
    .margin_animation {
      padding: 0;
    }
    &__wrapper .second_wrapper .wrap {
      padding-top: 100px;
      padding-bottom: 100px;
    }
    .border_radius {
      border-radius: 0px;
    }
    h3 {
      font-size: 30px;
      margin-bottom: 20px;
    }
    &__top {
      max-width: unset;
    }
    &__right {
      display: none;
    }
    &__left {
      flex: 0 0 100%;
      //max-width: 600px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      .categories {
        display: flex;
        bottom: 0;
        margin-bottom: 15px;
      }
      .project {
        padding-bottom: 0;
        padding-top: 10px;
        border-bottom: none;
        &::after {
          display: none;
        }
        .arrow {
          display: none;
        }
      }
      .img {
        position: relative;
        display: block;
        width: 100%;
        height: 0;
        padding-bottom: 56%;
        margin-top: 10px;
        background-color: $c2;
        img {
          opacity: 0.5;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          object-fit: cover;
          object-position: top;
        }
      }
      .content {
        background-color: $c2;
        padding: 25px 20px 35px;
      }
    }
  }
  @media #{$phones} {
    margin-bottom: 70px;
    &__top {
      margin-bottom: 50px;
    }
    h3 {
      font-size: 30px;
    }
    .project {
      .description {
        display: block;
      }
      .small_text {
        margin-top: 10px;
      }
    }
    &__left {
      .img {
        margin-top: 0;
      }
    }
    &__wrapper {
      .second_wrapper {
        .wrap {
          padding-top: 70px;
          padding-bottom: 70px;
        }
      }
    }
  }
}
/*
:'######:::'#######::'##::: ##:'########::::'###:::::'######::'########:
'##... ##:'##.... ##: ###:: ##:... ##..::::'## ##:::'##... ##:... ##..::
 ##:::..:: ##:::: ##: ####: ##:::: ##:::::'##:. ##:: ##:::..::::: ##::::
 ##::::::: ##:::: ##: ## ## ##:::: ##::::'##:::. ##: ##:::::::::: ##::::
 ##::::::: ##:::: ##: ##. ####:::: ##:::: #########: ##:::::::::: ##::::
 ##::: ##: ##:::: ##: ##:. ###:::: ##:::: ##.... ##: ##::: ##:::: ##::::
. ######::. #######:: ##::. ##:::: ##:::: ##:::: ##:. ######::::: ##::::
:......::::.......:::..::::..:::::..:::::..:::::..:::......::::::..:::::*/
.contact {
  h2 {
    text-align: center;
    margin: 0 auto;
    margin-bottom: -15px;
  }
  &__wrapper {
    display: flex;
    background-color: $c2;
    border-radius: 30px;
    padding: 120px;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 70px;
    align-items: center;
  }
  &__top {
    text-align: center;
    flex: 1 1 0;
    max-width: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__bottom {
    flex: 1 1 0;
  }
  .final_message {
    font-size: 1.5rem;
    text-align: center;
    padding: 20px 40px;
    background-color: $pink;
    color: $c0;
    display: none;
    &.failed_message {
      margin-top: 20px;
    }
  }
  form {
    display: flex;
    flex-wrap: wrap;
    row-gap: 30px;
    column-gap: 20px;
    max-width: 800px;
    .error {
      color: $pink;
      font-size: 12px;
      text-transform: uppercase;
      margin-top: 2px;
    }
    .input_wrapper {
      flex: 1 1 calc(50% - 10px);
      &.textarea {
        flex: 0 0 100%;
      }
      input:not([type="submit"]) {
        -webkit-appearance: none;
      }
      input:not([type="submit"]),
      textarea {
        width: 100%;
        border: none;
        border-bottom: 1px solid $white;
        background-color: rgba(0, 0, 0, 0);
        @include f1(400);
        font-size: 18px;
        color: $white;
        &:focus {
          outline: none;
        }
      }
      input:not([type="submit"]) input {
        height: 50px;
      }
      textarea {
        resize: none;
      }
      &.submit {
        flex: 0 0 100%;
      }
      input[type="submit"] {
        flex: 0 0 100%;
        text-align: center;
        background-color: $white;
        border: none;
        @include f1(400);
        cursor: pointer;
        font-size: 18px;
        width: 100%;
        border-radius: 5px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: $transition;
        text-transform: uppercase;
        &:hover {
          background-color: $pink;
          transition: $transition;
        }
      }
    }
  }
  @media #{$tablets} {
    h2 {
      margin-bottom: -12px;
    }
    &__wrapper {
      padding: 70px 60px;
    }
  }
  @media #{$phones} {
    &__wrapper {
      padding: 50px 30px;
      gap: 40px;
    }
    .final_message {
      font-size: 1.2rem;
    }
    form {
      .input_wrapper {
        flex: 1 1 100%;
      }
    }
  }
}
/*
'########::'#######:::'#######::'########:'########:'########::
 ##.....::'##.... ##:'##.... ##:... ##..:: ##.....:: ##.... ##:
 ##::::::: ##:::: ##: ##:::: ##:::: ##:::: ##::::::: ##:::: ##:
 ######::: ##:::: ##: ##:::: ##:::: ##:::: ######::: ########::
 ##...:::: ##:::: ##: ##:::: ##:::: ##:::: ##...:::: ##.. ##:::
 ##::::::: ##:::: ##: ##:::: ##:::: ##:::: ##::::::: ##::. ##::
 ##:::::::. #######::. #######::::: ##:::: ########: ##:::. ##:
..:::::::::.......::::.......::::::..:::::........::..:::::..::*/
.footer__legals {
  padding: 100px 0 20px;
  font-size: 16px;
  text-align: center;
  @media #{$phones} {
    font-size: 14px;
    padding: 70px 50px 20px;
  }
}
/*
:::'###::::'##::: ##:'####:'##::::'##::::'###::::'########:'####::'#######::'##::: ##:
::'## ##::: ###:: ##:. ##:: ###::'###:::'## ##:::... ##..::. ##::'##.... ##: ###:: ##:
:'##:. ##:: ####: ##:: ##:: ####'####::'##:. ##::::: ##::::: ##:: ##:::: ##: ####: ##:
'##:::. ##: ## ## ##:: ##:: ## ### ##:'##:::. ##:::: ##::::: ##:: ##:::: ##: ## ## ##:
 #########: ##. ####:: ##:: ##. #: ##: #########:::: ##::::: ##:: ##:::: ##: ##. ####:
 ##.... ##: ##:. ###:: ##:: ##:.:: ##: ##.... ##:::: ##::::: ##:: ##:::: ##: ##:. ###:
 ##:::: ##: ##::. ##:'####: ##:::: ##: ##:::: ##:::: ##::::'####:. #######:: ##::. ##:
..:::::..::..::::..::....::..:::::..::..:::::..:::::..:::::....:::.......:::..::::..::*/
@keyframes move {
  to {
    transform: translateX(6px);
  }
}
@keyframes move_horizontal {
  to {
    transform: translateY(-50%) translateX(10px);
  }
}
